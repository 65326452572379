<template>
  <div
    :class="[
    'layout--main',
      layoutTypeClass,
      navbarClasses,
      footerClasses,
      {'no-scroll': isAppPage}
    ]"
  >
    <v-nav-menu
      :navMenuItems="navMenuItems"
      parent=".layout--main"
      title="PriseRDV"
    />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

      <!-- Navbar -->
      <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
        <the-navbar-horizontal
          :class="[
            {'text-white' : isNavbarDark  && !isThemeDark},
            {'text-base'  : !isNavbarDark && isThemeDark}
          ]"
          :connected="connected"
          :navbarType= "navbarType"
        />

        <div style="height: 62px" v-if="navbarType === 'static'" />

        <h-nav-menu
          :class="[
            {'text-white' : isNavbarDark  && !isThemeDark},
            {'text-base'  : !isNavbarDark && isThemeDark}
          ]"
          :navMenuItems="navMenuItems" />
      </template>

      <template v-else>
        <the-navbar-vertical
          :class="[
            {'text-white' : isNavbarDark  && !isThemeDark},
            {'text-base'  : !isNavbarDark && isThemeDark}
          ]"
          :navbarColor="navbarColor"
        />
      </template>
      <!-- /Navbar -->

      <div class="content-wrapper">
        <div class="router-view">
          <div class="router-content">
            <div class="content-area__content">
              <back-to-top
                v-if="!hideScrollToTop"
                bottom="5%"
                visibleoffset="500"
                :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
              >
                <vs-button
                  class="shadow-lg btn-back-to-top"
                  icon="icon-arrow-up"
                  icon-pack="feather"
                />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @changeRouteTitle="changeRouteTitle"
                  @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<script>
import BackToTop from 'vue-backtotop';
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems';
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue';
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue';
import TheFooter from '@/layouts/components/TheFooter.vue';
import themeConfig from '@/../themeConfig';
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue';
import '@/assets/css/custom.css';
import auth from '@/auth';

export default {
  name: 'MainLayout',
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
  },
  data() {
    return {
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$route.meta.pageTitle,
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff';
      this.updateNavbarColor(color);
    },
    '$store.state.mainLayoutType': function (val) {
      this.setNavMenuVisibility(val);
    },
  },
  computed: {
    connected() {
      return auth.user.authenticated;
    },
    bodyOverlay() { return this.$store.state.bodyOverlay; },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced';
        if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg';
        return 'content-area-full';
      }
      // else if(this.mainLayoutType === "boxed") return "content-area-reduced"
      return 'content-area-full';
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static',
      };
    },
    isAppPage() {
      return this.$route.meta.no_scroll;
    },
    isThemeDark() { return this.$store.state.theme === 'dark'; },
    layoutTypeClass() { return `main-${this.mainLayoutType}`; },
    mainLayoutType() { return this.$store.state.mainLayoutType; },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating',
      };
    },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth; },
    windowWidth() { return this.$store.state.windowWidth; },
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title;
    },
    updateNavbarColor(val) {
      this.navbarColor = val;
      if (val === '#fff') this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false);
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu');
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
      }
    },
  },
  created() {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor;
    this.updateNavbarColor(color);
    this.setNavMenuVisibility(this.$store.state.mainLayoutType);
  },
};

</script>

<style >
  h4 {font-weight: 700;}
  .layout--full-page .full-page-bg-color {
    background-color: #e0e0e0;
  }
  .bookmark-container,.navbar-fuzzy-search{display: none !important;}
</style>
