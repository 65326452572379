export default [
  {
    url: { name: 'admin-dashboard' },
    name: 'Tableau de bord',
    slug: 'tableau-de-bord',
    icon: 'GridIcon',
  },
  {
    url: { name: 'admin-teleconsultants' },
    admin: true,
    name: 'Téleconseillers',
    slug: 'teleconseillers',
    icon: 'UsersIcon',
  },
  {
    url: { name: 'admin-orders' },
    name: 'Commandes',
    slug: 'commandes',
    icon: 'ShoppingCartIcon',
  },
  {
    url: { name: 'admin-diary' },
    name: 'Agendas',
    slug: 'agenads',
    icon: 'CalendarIcon',
  },
  {
    url: { name: 'admin-appointments' },
    name: 'Rendez-Vous',
    slug: 'admin-appointments',
    icon: 'BookmarkIcon',
  },
  {
    url: { name: 'admin-cancellation-requests' },
    name: 'Annulations RDV',
    slug: 'annulations',
    icon: 'FileMinusIcon',
  },
  {
    url: { name: 'admin-formulas' },
    admin: true,
    name: 'Formules',
    slug: 'formules',
    icon: 'PackageIcon',
  },
  {
    url: { name: 'admin-offers' },
    admin: true,
    name: 'Offres',
    slug: 'Offres',
    icon: 'GiftIcon',

  },
];
