var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'layout--main',
    _vm.layoutTypeClass,
    _vm.navbarClasses,
    _vm.footerClasses,
    {'no-scroll': _vm.isAppPage}
  ]},[_c('v-nav-menu',{attrs:{"navMenuItems":_vm.navMenuItems,"parent":".layout--main","title":"PriseRDV"}}),_c('div',{class:[_vm.contentAreaClass, {'show-overlay': _vm.bodyOverlay}],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"connected":_vm.connected,"navbarType":_vm.navbarType}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"navMenuItems":_vm.navMenuItems}})]:[_c('the-navbar-vertical',{class:[
          {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
          {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
        ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","visibleoffset":"500","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon":"icon-arrow-up","icon-pack":"feather"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle,"setAppClasses":function (classesStr) { return _vm.$emit('setAppClasses', classesStr); }}})],1)],1)])])]),_c('the-footer')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }